import React, { useState } from "react";
import Smalllogo from "../../Images/smallLogo.png";
import { Link } from "react-router-dom";
import SeparateDatePicker from "../component/SeparateDatePicker";

const FLIGHTRegistration = () => {
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    phoneNumber: "",
    gender: "",
    dateOfBirth: "",
    personalAddress: "",
    bloodGroup: "",
    overseasTravel: "",

    // Academic Information
    institution: "",
    institutionAddress: "",
    currentSemester: "",
    branch: "",

    // SSC Details
    sscSchoolName: "",
    sscSchoolAddress: "",
    sscPassingYear: "",

    // HSC Details
    hscCollegeName: "",
    hscCollegeAddress: "",
    hscPassingYear: "",

    // Documents
    aadharCard: "",
    panCard: "",
    hasIndianPassport: null,
    passportNumber: "",
    passportExpiryDate: "",
    hasUSVisa: null,
    aadharDocument: null,
    passportDocument: null,

    // Parents Information
    mothersName: "",
    fathersName: "",
    mothersOccupation: "",
    fathersOccupation: "",
    mothersContact: "",
    fathersContact: "",
    mothersDateOfBirth: "",
    fathersDateOfBirth: "",
    parentsAsSponsor: null,

    // Declaration
    declarationAccepted: false,
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [canProceed, setCanProceed] = useState(false);
  const [isRegistrationComplete, setIsRegistrationComplete] = useState(false);

  const [fileNames, setFileNames] = useState({
    passportDocument: "No file chosen",
    aadharDocument: "No file chosen",
  });

  const Branch = [
    "Computer Science Engineering",
    "AI-DS / AI-ML",
    "Information Technology",
    "Mechanical Engineering",
    "Civil Engineering",
    "Electrical Engineering",
    "Electronics and Communication Engineering",
    "Chemical Engineering",
    "Biotechnology",
    "Engineering",
    "Bachelor of Design",
    "Bachelor of Commerce (B.Com)",
    "Bachelor of Business Administration (BBA)",
    "Bachelor of Science (B.Sc Computer)",
    "Bachelor of Computer Application (BCA)",
    "Bachelor (Any)",
    "Master of Design",
    "Master of Technology (M.Tech)",
    "Master of Science (M.Sc)",
    "Master of Computer Applications (MCA)",
    "Master of Business Administration (MBA)",
    "Master of Commerce (M.Com)",
    "Master of Engineering (M.E)",
    "Masters (Any)",
  ];

  const handleChange = (e) => {
    const { name, value, type, files, checked } = e.target;
    if (type === "file") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0] || null,
      }));
      setFileNames((prevFileNames) => ({
        ...prevFileNames,
        [name]: files[0] ? files[0].name : "No file chosen",
      }));
    } else if (type === "radio") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value === "yes",
      }));
    } else if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date, fieldName) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: date,
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    // Date validation function for Odoo format (YYYY-MM-DD)
    // const isValidDateString = (dateStr) => {
    //   if (!dateStr) return false;
    //   const pattern = /^\d{4}-\d{2}-\d{2}$/;
    //   if (!pattern.test(dateStr)) return false;

    //   // Validate the actual date
    //   const [year, month, day] = dateStr.split("-").map(Number);
    //   const date = new Date(year, month - 1, day);
    //   return (
    //     date.getFullYear() === year &&
    //     date.getMonth() === month - 1 &&
    //     date.getDate() === day
    //   );
    // };
    const isValidDateString = (dateStr) => {
      if (!dateStr) return false;
      const pattern = /^\d{4}-\d{2}-\d{2}$/;
      if (!pattern.test(dateStr)) return false;

      const [year, month, day] = dateStr.split("-").map(Number);
      // Remove or update year validation

      const date = new Date(year, month - 1, day);
      return (
        date.getFullYear() === year &&
        date.getMonth() === month - 1 &&
        date.getDate() === day
      );
    };

    if (!isValidDateString(formData.dateOfBirth)) {
      newErrors.dateOfBirth = "Valid date of birth is required";
    }

    // Personal Information validations
    if (!formData.name) newErrors.name = "Full name is required";
    if (!formData.gender) newErrors.gender = "Please select a gender";
    if (!formData.dateOfBirth)
      newErrors.dateOfBirth = "Date of birth is required";
    if (!formData.personalAddress)
      newErrors.personalAddress = "Personal address is required";
    if (!/^\d{10}$/.test(formData.phoneNumber))
      newErrors.phoneNumber = "Invalid phone number";
    if (!formData.bloodGroup) newErrors.bloodGroup = "Blood group is required";

    // Academic Information validations
    if (!formData.institution)
      newErrors.institution = "Institution name is required";
    if (!formData.institutionAddress)
      newErrors.institutionAddress = "Institution address is required";
    if (!/^([1-9]|10)$/.test(formData.currentSemester))
      newErrors.currentSemester = "Invalid semester";
    if (!formData.branch) newErrors.branch = "Please select a branch";

    // SSC and HSC validations
    if (!formData.sscSchoolName)
      newErrors.sscSchoolName = "SSC school name is required";
    if (!formData.sscSchoolAddress)
      newErrors.sscSchoolAddress = "SSC school address is required";
    if (!formData.sscPassingYear)
      newErrors.sscPassingYear = "SSC passing year is required";
    if (!formData.hscCollegeName)
      newErrors.hscCollegeName = "HSC college name is required";
    if (!formData.hscCollegeAddress)
      newErrors.hscCollegeAddress = "HSC college address is required";
    if (!formData.hscPassingYear)
      newErrors.hscPassingYear = "HSC passing year is required";

    // Document validations
    // if (!/^\d{12}$/.test(formData.aadharCard))
    //   newErrors.aadharCard = "Invalid Aadhar Card number";
    // if (
    //   formData.panCard &&
    //   !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(formData.panCard)
    // ) {
    //   newErrors.panCard = "Invalid PAN Card number";
    // }
    if (formData.hasIndianPassport === null)
      newErrors.hasIndianPassport = "Please select passport option";
    if (formData.hasIndianPassport) {
      if (!formData.passportNumber)
        newErrors.passportNumber = "Passport number is required";
      if (!formData.passportExpiryDate)
        newErrors.passportExpiryDate = "Passport expiry date is required";
    }
    // if (!formData.aadharDocument)
    //   newErrors.aadharDocument = "Aadhar document is required";
    if (formData.hasIndianPassport && !formData.passportDocument) {
      newErrors.passportDocument = "Passport document is required";
    }

    // Parents Information validations
    if (!formData.mothersName)
      newErrors.mothersName = "Mother's name is required";
    if (!formData.fathersName)
      newErrors.fathersName = "Father's name is required";
    if (!formData.mothersDateOfBirth)
      newErrors.mothersDateOfBirth = "Mother's date of birth is required";
    if (!formData.fathersDateOfBirth)
      newErrors.fathersDateOfBirth = "Father's date of birth is required";
    if (!formData.mothersOccupation)
      newErrors.mothersOccupation = "Mother's occupation is required";
    if (!formData.fathersOccupation)
      newErrors.fathersOccupation = "Father's occupation is required";
    if (!/^\d{10}$/.test(formData.mothersContact))
      newErrors.mothersContact = "Invalid mother's contact";
    if (!/^\d{10}$/.test(formData.fathersContact))
      newErrors.fathersContact = "Invalid father's contact";
    if (formData.parentsAsSponsor === null)
      newErrors.parentsAsSponsor = "Please select sponsor option";

    // Declaration validation
    if (!formData.declarationAccepted)
      newErrors.declarationAccepted = "Please accept the declaration";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleEmailCheck = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setMessage("");

    try {
      const response = await fetch(
        "https://us-central1-arcticturn1.cloudfunctions.net/checkEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: formData.email }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setIsEmailChecked(true);
      setCanProceed(data.canProceed);
      setMessage(data.message);
    } catch (error) {
      console.error("Error checking email:", error);
      setMessage(`An error occurred: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      setIsSubmitting(true);
      setMessage("");

      const formDataToSend = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        if (value instanceof File) {
          formDataToSend.append(key, value);
        } else if (typeof value === "boolean") {
          formDataToSend.append(key, value.toString());
        } else if (value !== null) {
          formDataToSend.append(key, value);
        }
      });

      try {
        const response = await fetch(
          "https://us-central1-arcticturn1.cloudfunctions.net/submitForm?type=registration",
          {
            method: "POST",
            body: formDataToSend,
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setMessage(data.message);
        setIsRegistrationComplete(true);
      } catch (error) {
        console.error("Error submitting form:", error);
        setMessage(`An error occurred: ${error.message}`);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleLogout = () => {
    setFormData({ email: "" });
    setIsEmailChecked(false);
    setCanProceed(false);
    setIsRegistrationComplete(false);
  };

  // if (isRegistrationComplete) {
  //   return (
  //     <div className="registration-form-body">
  //       <div className="registration-form">
  //         <img
  //           src={Smalllogo}
  //           className="flightinsights-wrapper-img"
  //           alt="Small Logo"
  //         />
  //         <h2 className="form-title">
  //           FLIGHT Mobility 2025
  //           <br /> Registration Form
  //         </h2>
  //         <div className="form-card feedbackCard">
  //           <h3 className="card-title">Registration Complete</h3>
  //           <div className="card-content">
  //             <p className="warning-message__success">
  //               You have successfully completed your registration.
  //             </p>
  //             <div className="button-group">
  //               <Link
  //                 to="/Flightregistration"
  //                 //className="submit-button"
  //                 // style={{
  //                 //   textDecoration: "none",
  //                 //   display: "inline-block",
  //                 // }}
  //               >
  //                 <button onClick={handleLogout} className="submit-button">
  //                   Log Out
  //                 </button>
  //               </Link>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }
  // Render function (UI) goes here
  // ...

  if (isRegistrationComplete) {
    return (
      <div className="registration-form-body">
        <div className="registration-form">
          <img
            src={Smalllogo}
            className="flightinsights-wrapper-img"
            alt="Small Logo"
          />
          <h2 className="form-title">
            FLIGHT Mobility 2025
            <br /> Registration Form
          </h2>
          <div className="form-card feedbackCard">
            <h3 className="card-title">Registration Complete</h3>
            <div className="card-content">
              <p className="warning-message__success">
                You have successfully completed your registration.
              </p>
              <div className="button-group">
                <Link to="/Flightregistration">
                  <button onClick={handleLogout} className="submit-button">
                    Register
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // return (
  //   <div className="registration-form-body">
  //     <form className="registration-form" onSubmit={handleSubmit}>
  //       <img
  //         src={Smalllogo}
  //         className="flightinsights-wrapper-img"
  //         alt="Small Logo"
  //       />
  //       <h2 className="form-title">
  //         FLIGHT Mobility 2025
  //         <br /> Registration Form
  //       </h2>
  //       {!isEmailChecked || !canProceed ? (
  //         <div className="form-card feedbackCard">
  //           <h3 className="card-title">Registration Login</h3>
  //           <div className="card-content">
  //             <div className="form-group">
  //               <label htmlFor="email">Email Address</label>
  //               <input
  //                 type="email"
  //                 id="email"
  //                 name="email"
  //                 value={formData.email}
  //                 onChange={handleChange}
  //                 required
  //               />
  //               {errors.email && (
  //                 <span className="error-message">{errors.email}</span>
  //               )}
  //             </div>
  //             {isEmailChecked && !canProceed && message && (
  //               <span className="warning-message__error">{message}</span>
  //             )}
  //             <button
  //               type="button"
  //               onClick={handleEmailCheck}
  //               disabled={isSubmitting}
  //               className="submit-button"
  //               style={{ width: "40%" }}
  //             >
  //               {isSubmitting ? "Loggin in..." : "Log In"}
  //             </button>
  //           </div>
  //         </div>
  //       ) : (
  //         <>
  //           <div className="form-cards">
  //             {/* {Object.keys(errors).length > 0 && (
  //               <div className="error-summary">
  //                 Please correct the errors in the form before submitting.
  //               </div>
  //             )} */}
  //             <div className="form-card">
  //               <h3 className="card-title">Personal Information</h3>
  //               <div className="card-content">
  //                 <div className="form-row">
  //                   <div className="form-group">
  //                     <label htmlFor="name">Full Name (As per passport)*</label>
  //                     <input
  //                       type="text"
  //                       id="name"
  //                       name="name"
  //                       value={formData.name}
  //                       onChange={handleChange}
  //                       required
  //                     />
  //                     {errors.name && (
  //                       <span className="error-message">{errors.name}</span>
  //                     )}
  //                   </div>

  //                   <div className="form-group">
  //                     <label htmlFor="phone">Phone number*</label>
  //                     <input
  //                       type="tel"
  //                       id="phoneNumber"
  //                       name="phoneNumber"
  //                       value={formData.phoneNumber}
  //                       onChange={handleChange}
  //                       required
  //                     />
  //                     {errors.phoneNumber && (
  //                       <span className="error-message">{errors.phoneNumber}</span>
  //                     )}
  //                   </div>
  //                 </div>
  //                 <div className="form-row">
  //                   <div className="form-group">
  //                     <label htmlFor="bloodGroup">Blood Group*</label>
  //                     <input
  //                       type="text"
  //                       id="bloodGroup"
  //                       name="bloodGroup"
  //                       value={formData.bloodGroup}
  //                       onChange={handleChange}
  //                       required
  //                     />
  //                     {errors.bloodGroup && (
  //                       <span className="error-message">
  //                         {errors.bloodGroup}
  //                       </span>
  //                     )}
  //                   </div>
  //                   <div className="form-group">
  //                     <label htmlFor="gender">Gender*</label>
  //                     <select
  //                       id="gender"
  //                       name="gender"
  //                       value={formData.gender}
  //                       onChange={handleChange}
  //                       required
  //                       className="custom-select"
  //                     >
  //                       <option value="">Select Gender</option>
  //                       <option value="male">Male</option>
  //                       <option value="female">Female</option>
  //                     </select>
  //                     {errors.gender && (
  //                       <span className="error-message">{errors.gender}</span>
  //                     )}
  //                   </div>
  //                 </div>
  //                 <div className="form-group">
  //                   <label htmlFor="overseasTravel">
  //                     Have you travelled OVERSEAS? (Destination, Duration etc.
  //                     or NA)*
  //                   </label>
  //                   <textarea
  //                     id="overseasTravel"
  //                     name="overseasTravel"
  //                     value={formData.overseasTravel}
  //                     onChange={handleChange}
  //                     required
  //                   ></textarea>
  //                   {errors.overseasTravel && (
  //                     <span className="error-message">
  //                       {errors.overseasTravel}
  //                     </span>
  //                   )}
  //                 </div>
  //               </div>
  //             </div>

  //             <div className="form-card">
  //               <h3 className="card-title">Academic Information</h3>
  //               <div className="card-content">
  //                 <div className="form-group">
  //                   <label htmlFor="institution">
  //                     Name of Institution/University/College*
  //                   </label>
  //                   <input
  //                     type="text"
  //                     id="institution"
  //                     name="institution"
  //                     value={formData.institution}
  //                     onChange={handleChange}
  //                     required
  //                   />
  //                   {errors.institution && (
  //                     <span className="error-message">
  //                       {errors.institution}
  //                     </span>
  //                   )}
  //                 </div>
  //                 <div className="form-row">
  //                   <div className="form-group">
  //                     <label htmlFor="currentSemester">Current Semester*</label>
  //                     <input
  //                       type="text"
  //                       id="currentSemester"
  //                       name="currentSemester"
  //                       value={formData.currentSemester}
  //                       onChange={handleChange}
  //                       required
  //                     />
  //                     {errors.currentSemester && (
  //                       <span className="error-message">
  //                         {errors.currentSemester}
  //                       </span>
  //                     )}
  //                   </div>
  //                   <div className="form-group">
  //                     <label htmlFor="branch">Branch*</label>
  //                     <select
  //                       id="branch"
  //                       name="branch"
  //                       value={formData.branch}
  //                       onChange={handleChange}
  //                       required
  //                       className="custom-select"
  //                     >
  //                       <option value="">Select Branch</option>
  //                       {Branch.map((branch, index) => (
  //                         <option key={index} value={branch}>
  //                           {branch}
  //                         </option>
  //                       ))}
  //                     </select>
  //                     {errors.branch && (
  //                       <span className="error-message">{errors.branch}</span>
  //                     )}
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>

  //             <div className="form-card">
  //               <h3 className="card-title">Your Documents (student)</h3>
  //               <div className="card-content">
  //                 <div className="form-row">
  //                   <div className="form-group">
  //                     <label htmlFor="aadharCard">Aadhar Card Number*</label>
  //                     <input
  //                       type="text"
  //                       id="aadharCard"
  //                       name="aadharCard"
  //                       value={formData.aadharCard}
  //                       onChange={handleChange}
  //                       required
  //                     />
  //                     {errors.aadharCard && (
  //                       <span className="error-message">
  //                         {errors.aadharCard}
  //                       </span>
  //                     )}
  //                   </div>
  //                   <div className="form-group">
  //                     <label htmlFor="panCard">PAN Card Number</label>
  //                     <input
  //                       type="text"
  //                       id="panCard"
  //                       name="panCard"
  //                       value={formData.panCard}
  //                       onChange={handleChange}
  //                     />
  //                     {errors.panCard && (
  //                       <span className="error-message">{errors.panCard}</span>
  //                     )}
  //                   </div>
  //                 </div>
  //                 <div className="form-row">
  //                   <div className="form-group">
  //                     <label>Do you possess a valid INDIAN passport?*</label>
  //                     <div className="radio-group">
  //                       <label>
  //                         <input
  //                           type="radio"
  //                           name="hasIndianPassport"
  //                           value="yes"
  //                           checked={formData.hasIndianPassport === true}
  //                           onChange={handleChange}
  //                           required
  //                         />{" "}
  //                         Yes
  //                       </label>
  //                       <label>
  //                         <input
  //                           type="radio"
  //                           name="hasIndianPassport"
  //                           value="no"
  //                           checked={formData.hasIndianPassport === false}
  //                           onChange={handleChange}
  //                           required
  //                         />{" "}
  //                         No
  //                       </label>
  //                     </div>
  //                     {errors.hasIndianPassport && (
  //                       <span className="error-message">
  //                         {errors.hasIndianPassport}
  //                       </span>
  //                     )}
  //                   </div>
  //                   <div className="form-group">
  //                     <label>Do you have a valid US visa?*</label>
  //                     <div className="radio-group">
  //                       <label>
  //                         <input
  //                           type="radio"
  //                           name="hasUSVisa"
  //                           value="yes"
  //                           checked={formData.hasUSVisa === true}
  //                           onChange={handleChange}
  //                           required
  //                         />{" "}
  //                         Yes
  //                       </label>
  //                       <label>
  //                         <input
  //                           type="radio"
  //                           name="hasUSVisa"
  //                           value="no"
  //                           checked={formData.hasUSVisa === false}
  //                           onChange={handleChange}
  //                           required
  //                         />{" "}
  //                         No
  //                       </label>
  //                     </div>
  //                     {errors.hasUSVisa && (
  //                       <span className="error-message">
  //                         {errors.hasUSVisa}
  //                       </span>
  //                     )}
  //                   </div>
  //                 </div>
  //                 {formData.hasIndianPassport && (
  //                   <div className="form-group">
  //                     <label htmlFor="passportNumber">Passport Number*</label>
  //                     <input
  //                       type="text"
  //                       id="passportNumber"
  //                       name="passportNumber"
  //                       value={formData.passportNumber}
  //                       onChange={handleChange}
  //                       required
  //                     />
  //                     {errors.passportNumber && (
  //                       <span className="error-message">
  //                         {errors.passportNumber}
  //                       </span>
  //                     )}
  //                   </div>
  //                 )}
  //                 <div className="form-row">
  //                   <div className="form-group">
  //                     <label htmlFor="aadharDocument">Upload Adhar Card*</label>
  //                     <label className="custom-file-upload">
  //                       <input
  //                         type="file"
  //                         id="aadharDocument"
  //                         name="aadharDocument"
  //                         onChange={handleChange}
  //                         accept=".pdf,.jpg,.jpeg,.png"
  //                       />
  //                       Choose File
  //                     </label>
  //                     <span className="file-name">
  //                       {fileNames.aadharDocument}
  //                     </span>
  //                     {errors.aadharDocument && (
  //                       <span className="error-message">
  //                         {errors.aadharDocument}
  //                       </span>
  //                     )}
  //                   </div>
  //                   <div className="form-group">
  //                     <label htmlFor="passportDocument">Upload Passport*</label>
  //                     <label className="custom-file-upload">
  //                       <input
  //                         type="file"
  //                         id="passportDocument"
  //                         name="passportDocument"
  //                         onChange={handleChange}
  //                         accept=".pdf,.jpg,.jpeg,.png"
  //                       />
  //                       Choose File
  //                     </label>
  //                     <span className="file-name">
  //                       {fileNames.passportDocument}
  //                     </span>
  //                     {errors.passportDocument && (
  //                       <span className="error-message">
  //                         {errors.passportDocument}
  //                       </span>
  //                     )}
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>

  //             <div className="form-card">
  //               <h3 className="card-title">Parents Information</h3>
  //               <div className="card-content">
  //                 <div className="form-row">
  //                   <div className="form-group">
  //                     <label htmlFor="mothersName">Mother's Full Name*</label>
  //                     <input
  //                       type="text"
  //                       id="mothersName"
  //                       name="mothersName"
  //                       value={formData.mothersName}
  //                       onChange={handleChange}
  //                       required
  //                     />
  //                     {errors.mothersName && (
  //                       <span className="error-message">
  //                         {errors.mothersName}
  //                       </span>
  //                     )}
  //                   </div>
  //                   <div className="form-group">
  //                     <label htmlFor="fathersName">Father's Full Name*</label>
  //                     <input
  //                       type="text"
  //                       id="fathersName"
  //                       name="fathersName"
  //                       value={formData.fathersName}
  //                       onChange={handleChange}
  //                       required
  //                     />
  //                     {errors.fathersName && (
  //                       <span className="error-message">
  //                         {errors.fathersName}
  //                       </span>
  //                     )}
  //                   </div>
  //                 </div>
  //                 <div className="form-row">
  //                   <div className="form-group">
  //                     <label htmlFor="mothersOccupation">
  //                       Mother's Occupation*
  //                     </label>
  //                     <input
  //                       type="text"
  //                       id="mothersOccupation"
  //                       name="mothersOccupation"
  //                       value={formData.mothersOccupation}
  //                       onChange={handleChange}
  //                       required
  //                     />
  //                     {errors.mothersOccupation && (
  //                       <span className="error-message">
  //                         {errors.mothersOccupation}
  //                       </span>
  //                     )}
  //                   </div>
  //                   <div className="form-group">
  //                     <label htmlFor="fathersOccupation">
  //                       Father's Occupation*
  //                     </label>
  //                     <input
  //                       type="text"
  //                       id="fathersOccupation"
  //                       name="fathersOccupation"
  //                       value={formData.fathersOccupation}
  //                       onChange={handleChange}
  //                       required
  //                     />
  //                     {errors.fathersOccupation && (
  //                       <span className="error-message">
  //                         {errors.fathersOccupation}
  //                       </span>
  //                     )}
  //                   </div>
  //                 </div>
  //                 <div className="form-row">
  //                   <div className="form-group">
  //                     <label htmlFor="mothersContact">
  //                       Mother's Contact Number*
  //                     </label>
  //                     <input
  //                       type="tel"
  //                       id="mothersContact"
  //                       name="mothersContact"
  //                       value={formData.mothersContact}
  //                       onChange={handleChange}
  //                       required
  //                     />
  //                     {errors.mothersContact && (
  //                       <span className="error-message">
  //                         {errors.mothersContact}
  //                       </span>
  //                     )}
  //                   </div>
  //                   <div className="form-group">
  //                     <label htmlFor="fathersContact">
  //                       Father's Contact Number*
  //                     </label>
  //                     <input
  //                       type="tel"
  //                       id="fathersContact"
  //                       name="fathersContact"
  //                       value={formData.fathersContact}
  //                       onChange={handleChange}
  //                       required
  //                     />
  //                     {errors.fathersContact && (
  //                       <span className="error-message">
  //                         {errors.fathersContact}
  //                       </span>
  //                     )}
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>

  //           <button
  //             type="submit"
  //             className="submit-button"
  //             disabled={isSubmitting}
  //           >
  //             {isSubmitting ? "Submitting..." : "Submit"}
  //           </button>
  //         </>
  //       )}

  //       {/* {message && (
  //         <p
  //           className={`message ${
  //             message.includes("error") ? "error" : "success"
  //           }`}
  //         >
  //           {message}
  //         </p>
  //       )} */}
  //     </form>
  //   </div>
  // );
  return (
    <div className="registration-form-body">
      <form className="registration-form" onSubmit={handleSubmit}>
        <img
          src={Smalllogo}
          className="flightinsights-wrapper-img"
          alt="Small Logo"
        />
        <h2 className="form-title">
          Global Internship Program 2025
          <br /> Registration Form
        </h2>

        {!isEmailChecked || !canProceed ? (
          <div className="form-card feedbackCard">
            <h3 className="card-title">Registration Login</h3>
            <div className="card-content">
              <div className="form-group">
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                {errors.email && (
                  <span className="error-message">{errors.email}</span>
                )}
              </div>
              {isEmailChecked && !canProceed && message && (
                <span className="warning-message__error">{message}</span>
              )}
              <button
                type="button"
                onClick={handleEmailCheck}
                disabled={isSubmitting}
                className="submit-button"
                style={{ width: "40%" }}
              >
                {isSubmitting ? "Logging in..." : "Register"}
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className="form-cards">
              {/* Personal Information */}
              <div className="form-card">
                <h3 className="card-title">Personal Information</h3>
                <div className="card-content">
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="name">Full Name (As per passport)*</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                      {errors.name && (
                        <span className="error-message">{errors.name}</span>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="phone">Phone number*</label>
                      <input
                        type="tel"
                        id="phone"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        required
                      />
                      {errors.phoneNumber && (
                        <span className="error-message">
                          {errors.phoneNumber}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="dateOfBirth">Date of Birth*</label>
                      {/* <DatePicker
                        selected={formData.dateOfBirth}
                        onChange={(date) =>
                          handleDateChange(date, "dateOfBirth")
                        }
                        dateFormat="dd/MM/yyyy"
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={100}
                        placeholderText="Select date of birth"
                        className="custom-datepicker"
                        required
                      /> */}
                      <SeparateDatePicker
                        selectedDate={formData.dateOfBirth}
                        onChange={(date) =>
                          handleDateChange(date, "dateOfBirth")
                        }
                        placeholderText="Select date of birth"
                      />

                      {errors.dateOfBirth && (
                        <span className="error-message">
                          {errors.dateOfBirth}
                        </span>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="gender">Gender*</label>
                      <select
                        id="gender"
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                        required
                        className="custom-select"
                      >
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                      {errors.gender && (
                        <span className="error-message">{errors.gender}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="personalAddress">Personal Address*</label>
                    <textarea
                      id="personalAddress"
                      name="personalAddress"
                      value={formData.personalAddress}
                      onChange={handleChange}
                      required
                    />
                    {errors.personalAddress && (
                      <span className="error-message">
                        {errors.personalAddress}
                      </span>
                    )}
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="bloodGroup">Blood Group*</label>
                      <input
                        type="text"
                        id="bloodGroup"
                        name="bloodGroup"
                        value={formData.bloodGroup}
                        onChange={handleChange}
                        required
                      />
                      {errors.bloodGroup && (
                        <span className="error-message">
                          {errors.bloodGroup}
                        </span>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="overseasTravel">
                        Overseas Travel History*
                      </label>
                      <textarea
                        id="overseasTravel"
                        name="overseasTravel"
                        value={formData.overseasTravel}
                        onChange={handleChange}
                        placeholder="Enter travel history or NA"
                        required
                      />
                      {errors.overseasTravel && (
                        <span className="error-message">
                          {errors.overseasTravel}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Academic Information */}
              <div className="form-card">
                <h3 className="card-title">Current Academic Information</h3>
                <div className="card-content">
                  <div className="form-group">
                    <label htmlFor="institution">Institution Name*</label>
                    <input
                      type="text"
                      id="institution"
                      name="institution"
                      value={formData.institution}
                      onChange={handleChange}
                      required
                    />
                    {errors.institution && (
                      <span className="error-message">
                        {errors.institution}
                      </span>
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="institutionAddress">
                      Institution Address*
                    </label>
                    <textarea
                      id="institutionAddress"
                      name="institutionAddress"
                      value={formData.institutionAddress}
                      onChange={handleChange}
                      required
                    />
                    {errors.institutionAddress && (
                      <span className="error-message">
                        {errors.institutionAddress}
                      </span>
                    )}
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="currentSemester">Current Semester*</label>
                      <input
                        type="number"
                        id="currentSemester"
                        name="currentSemester"
                        value={formData.currentSemester}
                        onChange={handleChange}
                        min="1"
                        max="10"
                        required
                      />
                      {errors.currentSemester && (
                        <span className="error-message">
                          {errors.currentSemester}
                        </span>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="branch">Branch*</label>
                      <select
                        id="branch"
                        name="branch"
                        value={formData.branch}
                        onChange={handleChange}
                        required
                        className="custom-select"
                      >
                        <option value="">Select Branch</option>
                        {Branch.map((branch, index) => (
                          <option key={index} value={branch}>
                            {branch}
                          </option>
                        ))}
                      </select>
                      {errors.branch && (
                        <span className="error-message">{errors.branch}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* SSC Details Card */}
              <div className="form-card">
                <h3 className="card-title">10th School Details</h3>
                <div className="card-content">
                  <div className="form-group">
                    <label htmlFor="sscSchoolName">School Name*</label>
                    <input
                      type="text"
                      id="sscSchoolName"
                      name="sscSchoolName"
                      value={formData.sscSchoolName}
                      onChange={handleChange}
                      required
                    />
                    {errors.sscSchoolName && (
                      <span className="error-message">
                        {errors.sscSchoolName}
                      </span>
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="sscSchoolAddress">School Address*</label>
                    <textarea
                      id="sscSchoolAddress"
                      name="sscSchoolAddress"
                      value={formData.sscSchoolAddress}
                      onChange={handleChange}
                      required
                    />
                    {errors.sscSchoolAddress && (
                      <span className="error-message">
                        {errors.sscSchoolAddress}
                      </span>
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="sscPassingYear">Year of Passing*</label>
                    <input
                      type="text"
                      id="sscPassingYear"
                      name="sscPassingYear"
                      value={formData.sscPassingYear}
                      onChange={handleChange}
                      required
                    />
                    {errors.sscPassingYear && (
                      <span className="error-message">
                        {errors.sscPassingYear}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              {/* HSC Details Card */}
              <div className="form-card">
                <h3 className="card-title">12th College Details</h3>
                <div className="card-content">
                  <div className="form-group">
                    <label htmlFor="hscCollegeName">College Name*</label>
                    <input
                      type="text"
                      id="hscCollegeName"
                      name="hscCollegeName"
                      value={formData.hscCollegeName}
                      onChange={handleChange}
                      required
                    />
                    {errors.hscCollegeName && (
                      <span className="error-message">
                        {errors.hscCollegeName}
                      </span>
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="hscCollegeAddress">College Address*</label>
                    <textarea
                      id="hscCollegeAddress"
                      name="hscCollegeAddress"
                      value={formData.hscCollegeAddress}
                      onChange={handleChange}
                      required
                    />
                    {errors.hscCollegeAddress && (
                      <span className="error-message">
                        {errors.hscCollegeAddress}
                      </span>
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="hscPassingYear">Year of Passing*</label>
                    <input
                      type="text"
                      id="hscPassingYear"
                      name="hscPassingYear"
                      value={formData.hscPassingYear}
                      onChange={handleChange}
                      required
                    />
                    {errors.hscPassingYear && (
                      <span className="error-message">
                        {errors.hscPassingYear}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              {/* Documents */}
              <div className="form-card">
                <h3 className="card-title">Your Documents (student)</h3>
                <div className="card-content">
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="aadharCard">
                        Aadhar Card Number (for India)
                      </label>
                      <input
                        type="text"
                        id="aadharCard"
                        name="aadharCard"
                        value={formData.aadharCard}
                        onChange={handleChange}
                      />
                      {errors.aadharCard && (
                        <span className="error-message">
                          {errors.aadharCard}
                        </span>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="panCard">
                        PAN Card Number (for India)
                      </label>
                      <input
                        type="text"
                        id="panCard"
                        name="panCard"
                        value={formData.panCard}
                        onChange={handleChange}
                      />
                      {errors.panCard && (
                        <span className="error-message">{errors.panCard}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label>Do you possess a valid passport?*</label>
                      <div className="radio-group">
                        <label>
                          <input
                            type="radio"
                            name="hasIndianPassport"
                            value="yes"
                            checked={formData.hasIndianPassport === true}
                            onChange={handleChange}
                            required
                          />{" "}
                          Yes
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="hasIndianPassport"
                            value="no"
                            checked={formData.hasIndianPassport === false}
                            onChange={handleChange}
                            required
                          />{" "}
                          No
                        </label>
                      </div>
                      {errors.hasIndianPassport && (
                        <span className="error-message">
                          {errors.hasIndianPassport}
                        </span>
                      )}
                    </div>
                  </div>

                  {formData.hasIndianPassport && (
                    <div className="form-row">
                      <div className="form-group">
                        <label htmlFor="passportNumber">Passport Number*</label>
                        <input
                          type="text"
                          id="passportNumber"
                          name="passportNumber"
                          value={formData.passportNumber}
                          onChange={handleChange}
                          required
                        />
                        {errors.passportNumber && (
                          <span className="error-message">
                            {errors.passportNumber}
                          </span>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="passportExpiryDate">
                          Passport Expiry Date*
                        </label>
                        <SeparateDatePicker
                          selectedDate={formData.passportExpiryDate}
                          onChange={(date) =>
                            handleDateChange(date, "passportExpiryDate")
                          }
                          placeholderText="Select expiry date"
                        />
                        {errors.passportExpiryDate && (
                          <span className="error-message">
                            {errors.passportExpiryDate}
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="form-row">
                    <div className="form-group">
                      <label>Do you have a valid US visa?*</label>
                      <div className="radio-group">
                        <label>
                          <input
                            type="radio"
                            name="hasUSVisa"
                            value="yes"
                            checked={formData.hasUSVisa === true}
                            onChange={handleChange}
                            required
                          />{" "}
                          Yes
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="hasUSVisa"
                            value="no"
                            checked={formData.hasUSVisa === false}
                            onChange={handleChange}
                            required
                          />{" "}
                          No
                        </label>
                      </div>
                      {errors.hasUSVisa && (
                        <span className="error-message">
                          {errors.hasUSVisa}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="form-row">
                    {/* <div className="form-group">
                      <label htmlFor="aadharDocument">
                        Upload Aadhar Card
                      </label>
                      <label className="custom-file-upload">
                        <input
                          type="file"
                          id="aadharDocument"
                          name="aadharDocument"
                          onChange={handleChange}
                          accept=".pdf,.jpg,.jpeg,.png"
                        />
                        Choose File
                      </label>
                      <span className="file-name">
                        {fileNames.aadharDocument}
                      </span>
                      {errors.aadharDocument && (
                        <span className="error-message">
                          {errors.aadharDocument}
                        </span>
                      )}
                    </div> */}

                    {formData.hasIndianPassport && (
                      <div className="form-group">
                        <label htmlFor="passportDocument">
                          Upload Passport*
                        </label>
                        <label className="custom-file-upload">
                          <input
                            type="file"
                            id="passportDocument"
                            name="passportDocument"
                            onChange={handleChange}
                            accept=".pdf,.jpg,.jpeg,.png"
                          />
                          Choose File
                        </label>
                        <span className="file-name">
                          {fileNames.passportDocument}
                        </span>
                        {errors.passportDocument && (
                          <span className="error-message">
                            {errors.passportDocument}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Parents Information */}
              <div className="form-card">
                <h3 className="card-title">Parents Information</h3>
                <div className="card-content">
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="mothersName">Mother's Full Name*</label>
                      <input
                        type="text"
                        id="mothersName"
                        name="mothersName"
                        value={formData.mothersName}
                        onChange={handleChange}
                        required
                      />
                      {errors.mothersName && (
                        <span className="error-message">
                          {errors.mothersName}
                        </span>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="fathersName">Father's Full Name*</label>
                      <input
                        type="text"
                        id="fathersName"
                        name="fathersName"
                        value={formData.fathersName}
                        onChange={handleChange}
                        required
                      />
                      {errors.fathersName && (
                        <span className="error-message">
                          {errors.fathersName}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="mothersDateOfBirth">
                        Mother's Date of Birth*
                      </label>
                      <SeparateDatePicker
                        selectedDate={formData.mothersDateOfBirth}
                        onChange={(date) =>
                          handleDateChange(date, "mothersDateOfBirth")
                        }
                        placeholderText="Select mother's DOB"
                      />
                      {errors.mothersDateOfBirth && (
                        <span className="error-message">
                          {errors.mothersDateOfBirth}
                        </span>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="fathersDateOfBirth">
                        Father's Date of Birth*
                      </label>
                      <SeparateDatePicker
                        selectedDate={formData.fathersDateOfBirth}
                        onChange={(date) =>
                          handleDateChange(date, "fathersDateOfBirth")
                        }
                        placeholderText="Select father's DOB"
                      />

                      {errors.fathersDateOfBirth && (
                        <span className="error-message">
                          {errors.fathersDateOfBirth}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="mothersOccupation">
                        Mother's Occupation*
                      </label>
                      <input
                        type="text"
                        id="mothersOccupation"
                        name="mothersOccupation"
                        value={formData.mothersOccupation}
                        onChange={handleChange}
                        required
                      />
                      {errors.mothersOccupation && (
                        <span className="error-message">
                          {errors.mothersOccupation}
                        </span>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="fathersOccupation">
                        Father's Occupation*
                      </label>
                      <input
                        type="text"
                        id="fathersOccupation"
                        name="fathersOccupation"
                        value={formData.fathersOccupation}
                        onChange={handleChange}
                        required
                      />
                      {errors.fathersOccupation && (
                        <span className="error-message">
                          {errors.fathersOccupation}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="mothersContact">
                        Mother's Contact Number
                      </label>
                      <input
                        type="tel"
                        id="mothersContact"
                        name="mothersContact"
                        value={formData.mothersContact}
                        onChange={handleChange}
                      />
                      {errors.mothersContact && (
                        <span className="error-message">
                          {errors.mothersContact}
                        </span>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="fathersContact">
                        Father's Contact Number
                      </label>
                      <input
                        type="tel"
                        id="fathersContact"
                        name="fathersContact"
                        value={formData.fathersContact}
                        onChange={handleChange}
                      />
                      {errors.fathersContact && (
                        <span className="error-message">
                          {errors.fathersContact}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label>
                        Will your parents be sponsors for USA J1 visa?*
                      </label>
                      <div className="radio-group">
                        <label>
                          <input
                            type="radio"
                            name="parentsAsSponsor"
                            value="yes"
                            checked={formData.parentsAsSponsor === true}
                            onChange={handleChange}
                            required
                          />{" "}
                          Yes
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="parentsAsSponsor"
                            value="no"
                            checked={formData.parentsAsSponsor === false}
                            onChange={handleChange}
                            required
                          />{" "}
                          No
                        </label>
                      </div>
                      {errors.parentsAsSponsor && (
                        <span className="error-message">
                          {errors.parentsAsSponsor}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Declaration */}
              <div className="form-card">
                <h3 className="card-title">Declaration</h3>
                <div className="card-content">
                  <div className="form-group">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        name="declarationAccepted"
                        checked={formData.declarationAccepted}
                        onChange={handleChange}
                        required
                      />
                      <span className="checkbox-text">
                        I hereby declare that all the information provided above
                        is true and correct to the best of my knowledge. I
                        understand that any false or misleading information may
                        result in the rejection of my application.
                      </span>
                    </label>
                    {errors.declarationAccepted && (
                      <span className="error-message">
                        {errors.declarationAccepted}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <button
              type="submit"
              className="submit-button"
              disabled={isSubmitting || !formData.declarationAccepted}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </>
        )}
      </form>
    </div>
  );
};

export default FLIGHTRegistration;
