import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Smalllogo from "../../Images/smallLogo.png";

const InterviewSlotBooking = () => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [canProceed, setCanProceed] = useState(false);
  const [isSlotBooked, setIsSlotBooked] = useState(false);
  const [isSlotAlreadyBooked, setIsSlotAlreadyBooked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [isCalendarLoading, setIsCalendarLoading] = useState(true);

  useEffect(() => {
    if (canProceed) {
      const timer = setTimeout(() => {
        setIsCalendarLoading(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [canProceed]);

  const LoadingSpinner = () => (
    <div
      className="loading-container"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "400px",
        padding: "20px",
      }}
    >
      <div
        className="spinner"
        style={{
          width: "50px",
          height: "50px",
          border: "5px solid #f3f3f3",
          borderTop: "5px solid #1c7c7c",
          borderRadius: "50%",
          animation: "spin 1s linear infinite",
          marginBottom: "20px",
        }}
      />
      <div
        style={{
          color: "#1c7c7c",
          fontSize: "18px",
          fontWeight: 500,
        }}
      >
        Loading Calendar...
      </div>
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // Clear errors when typing
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  // const handleEmailCheck = async (e) => {
  //   e.preventDefault();
  //   setIsSubmitting(true);
  //   setMessage("");

  //   // Validate email format
  //   if (!formData.email) {
  //     setErrors({ email: "Email is required" });
  //     setIsSubmitting(false);
  //     return;
  //   }

  //   if (!validateEmail(formData.email)) {
  //     setErrors({ email: "Please enter a valid email address" });
  //     setIsSubmitting(false);
  //     return;
  //   }

  //   try {
  //     // First check if email exists and is registered
  //     const registrationResponse = await fetch(
  //       "https://us-central1-arcticturn1.cloudfunctions.net/checkEmail",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ email: formData.email }),
  //       }
  //     );

  //     if (!registrationResponse.ok) {
  //       throw new Error(`HTTP error! status: ${registrationResponse.status}`);
  //     }

  //     const registrationData = await registrationResponse.json();

  //     // Check if user exists AND is registered (x_studio_registered_1 field)
  //     if (registrationData.exists && registrationData.registered === "true") {
  //       // Now check if they have already booked a slot
  //       const slotCheckResponse = await fetch(
  //         "https://us-central1-arcticturn1.cloudfunctions.net/checkInterviewSlot",
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({ email: formData.email }),
  //         }
  //       );

  //       if (!slotCheckResponse.ok) {
  //         throw new Error(`HTTP error! status: ${slotCheckResponse.status}`);
  //       }

  //       const slotData = await slotCheckResponse.json();

  //       if (slotData.isBooked) {
  //         setMessage(
  //           "You have already booked an interview slot. Please contact support if you need to reschedule."
  //         );
  //         setCanProceed(false);
  //         setIsSlotAlreadyBooked(true);
  //       } else {
  //         setMessage("You can proceed to book your interview slot.");
  //         setCanProceed(true);
  //       }
  //     } else {
  //       // User either doesn't exist or isn't registered
  //       setMessage(
  //         "Please complete your registration before booking an interview slot."
  //       );
  //       setCanProceed(false);
  //     }

  //     setIsEmailChecked(true);
  //   } catch (error) {
  //     console.error("Error checking email:", error);
  //     setMessage(`An error occurred: ${error.message}`);
  //     setCanProceed(false);
  //   } finally {
  //     setIsSubmitting(false);
  //   }
  // };

  // const handleEmailCheck = async (e) => {
  //   e.preventDefault();
  //   setIsSubmitting(true);
  //   setMessage("");

  //   if (!formData.email) {
  //     setErrors({ email: "Email is required" });
  //     setIsSubmitting(false);
  //     return;
  //   }

  //   if (!validateEmail(formData.email)) {
  //     setErrors({ email: "Please enter a valid email address" });
  //     setIsSubmitting(false);
  //     return;
  //   }

  //   try {
  //     console.log("Checking registration for email:", formData.email);

  //     const registrationResponse = await fetch(
  //       "https://us-central1-arcticturn1.cloudfunctions.net/checkEmail",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ email: formData.email }),
  //       }
  //     );

  //     if (!registrationResponse.ok) {
  //       throw new Error(`HTTP error! status: ${registrationResponse.status}`);
  //     }

  //     const registrationData = await registrationResponse.json();
  //     console.log("Registration response data:", registrationData);

  //     // Check exact values
  //     console.log("exists value:", registrationData.exists);
  //     console.log("registered value:", registrationData.registered);
  //     console.log("canProceed value:", registrationData.canProceed);

  //     // First check if user exists AND is registered
  //     if (registrationData.exists && registrationData.canProceed) {
  //       // Changed condition here
  //       // Now check if they have already booked a slot
  //       console.log("User is registered, checking interview slot...");

  //       const slotCheckResponse = await fetch(
  //         "https://us-central1-arcticturn1.cloudfunctions.net/checkInterviewSlot",
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({ email: formData.email }),
  //         }
  //       );

  //       if (!slotCheckResponse.ok) {
  //         throw new Error(`HTTP error! status: ${slotCheckResponse.status}`);
  //       }

  //       const slotData = await slotCheckResponse.json();
  //       console.log("Slot check response:", slotData);

  //       if (slotData.isBooked) {
  //         setMessage(
  //           "You have already booked an interview slot. Please contact support if you need to reschedule."
  //         );
  //         setCanProceed(false);
  //         setIsSlotAlreadyBooked(true);
  //       } else {
  //         setMessage("You can proceed to book your interview slot.");
  //         setCanProceed(true);
  //       }
  //     } else {
  //       // User either doesn't exist or isn't registered
  //       const message = !registrationData.exists
  //         ? "Please complete your registration before booking an interview slot."
  //         : "Your registration is pending or incomplete. Please complete your registration first.";

  //       setMessage(message);
  //       setCanProceed(false);
  //     }

  //     setIsEmailChecked(true);
  //   } catch (error) {
  //     console.error("Error checking email:", error);
  //     setMessage(`An error occurred: ${error.message}`);
  //     setCanProceed(false);
  //   } finally {
  //     setIsSubmitting(false);
  //   }
  // };

  // const handleEmailCheck = async (e) => {
  //   e.preventDefault();
  //   setIsSubmitting(true);
  //   setMessage("");

  //   if (!formData.email) {
  //     setErrors({ email: "Email is required" });
  //     setIsSubmitting(false);
  //     return;
  //   }

  //   if (!validateEmail(formData.email)) {
  //     setErrors({ email: "Please enter a valid email address" });
  //     setIsSubmitting(false);
  //     return;
  //   }

  //   try {
  //     console.log("Checking registration for email:", formData.email);

  //     const registrationResponse = await fetch(
  //       "https://us-central1-arcticturn1.cloudfunctions.net/checkEmail",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ email: formData.email }),
  //       }
  //     );

  //     if (!registrationResponse.ok) {
  //       throw new Error(`HTTP error! status: ${registrationResponse.status}`);
  //     }

  //     const registrationData = await registrationResponse.json();
  //     console.log("Registration response data:", registrationData);

  //     // User exists but registration check
  //     if (registrationData.exists && registrationData.canProceed) {
  //       // Now check if they have already booked a slot
  //       console.log("User is registered, checking interview slot...");

  //       const slotCheckResponse = await fetch(
  //         "https://us-central1-arcticturn1.cloudfunctions.net/checkInterviewSlot",
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({ email: formData.email }),
  //         }
  //       );

  //       if (!slotCheckResponse.ok) {
  //         throw new Error(`HTTP error! status: ${slotCheckResponse.status}`);
  //       }

  //       const slotData = await slotCheckResponse.json();
  //       console.log("Slot check response:", slotData);

  //       if (slotData.isBooked) {
  //         setMessage(
  //           "You have already booked an interview slot. Please contact support if you need to reschedule."
  //         );
  //         setCanProceed(false);
  //         setIsSlotAlreadyBooked(true);
  //       } else {
  //         setMessage("You can proceed to book your interview slot.");
  //         setCanProceed(true);
  //       }
  //     } else if (registrationData.exists && !registrationData.canProceed) {
  //       // User exists but isn't allowed to proceed (already completed registration)
  //       setMessage(
  //         registrationData.message ||
  //           "You have already completed registration. Please contact support if you need assistance."
  //       );
  //       setCanProceed(false);
  //     } else {
  //       // User doesn't exist
  //       setMessage(
  //         "Please complete your registration before booking an interview slot."
  //       );
  //       setCanProceed(false);
  //     }

  //     setIsEmailChecked(true);
  //   } catch (error) {
  //     console.error("Error checking email:", error);
  //     setMessage(`An error occurred: ${error.message}`);
  //     setCanProceed(false);
  //   } finally {
  //     setIsSubmitting(false);
  //   }
  // };

  const handleEmailCheck = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setMessage("");

    if (!formData.email) {
      setErrors({ email: "Email is required" });
      setIsSubmitting(false);
      return;
    }

    if (!validateEmail(formData.email)) {
      setErrors({ email: "Please enter a valid email address" });
      setIsSubmitting(false);
      return;
    }

    try {
      // First check registration status
      const registrationResponse = await fetch(
        "https://us-central1-arcticturn1.cloudfunctions.net/checkEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: formData.email }),
        }
      );

      if (!registrationResponse.ok) {
        throw new Error(`HTTP error! status: ${registrationResponse.status}`);
      }

      const registrationData = await registrationResponse.json();

      // If user doesn't exist at all, ask them to register
      if (!registrationData.exists || registrationData.canProceed === true) {
        setMessage(
          "Please complete your registration before booking an interview slot."
        );
        setCanProceed(false);
        setIsEmailChecked(true);
        return;
      }

      // If user exists, check their interview slot status
      const slotCheckResponse = await fetch(
        "https://us-central1-arcticturn1.cloudfunctions.net/checkInterviewSlot",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: formData.email }),
        }
      );

      if (!slotCheckResponse.ok) {
        throw new Error(`HTTP error! status: ${slotCheckResponse.status}`);
      }

      const slotData = await slotCheckResponse.json();

      if (slotData.isBooked) {
        // User has already booked an interview slot
        setMessage(
          "You have already booked an interview slot. Please contact support if you need to reschedule."
        );
        setCanProceed(false);
        setIsSlotAlreadyBooked(true);
      } else if (registrationData.exists && !registrationData.canProceed) {
        // User is registered but hasn't booked a slot yet
        setMessage("You can proceed to book your interview slot.");
        setCanProceed(true);
      } else {
        // User exists but hasn't completed registration
        setMessage(
          "Please complete your registration before booking an interview slot."
        );
        setCanProceed(false);
      }

      setIsEmailChecked(true);
    } catch (error) {
      console.error("Error checking email:", error);
      setMessage(`An error occurred: ${error.message}`);
      setCanProceed(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSlotBooked = async () => {
    setIsSubmitting(true);
    setMessage("");

    try {
      const response = await fetch(
        "https://us-central1-arcticturn1.cloudfunctions.net/updateInterviewSlot",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: formData.email,
            slotBooked: true,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setMessage(
        "Your interview slot has been successfully booked. Please check your email for confirmation details."
      );
      setIsSlotBooked(true);
    } catch (error) {
      console.error("Error updating slot status:", error);
      setMessage(`An error occurred: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleLogout = () => {
    setFormData({ email: "" });
    setIsEmailChecked(false);
    setCanProceed(false);
    setIsSlotBooked(false);
  };

  if (isSlotBooked) {
    return (
      <div className="registration-form-body">
        <div className="registration-form">
          <img
            src={Smalllogo}
            className="flightinsights-wrapper-img"
            alt="Small Logo"
          />
          <h2 className="form-title">
            Global Internship Program 2025
            <br /> Interview Slot Booking
          </h2>
          <div className="form-card feedbackCard">
            <h3 className="card-title">Booking Complete</h3>
            <div className="card-content">
              <p className="warning-message__success">
                Your interview slot has been successfully booked. Please check
                your email for confirmation details.
              </p>
              <div className="button-group">
                <Link to="/InterviewSlotBooking">
                  <button onClick={handleLogout} className="submit-button">
                    Log Out
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="registration-form-body">
      <div className="registration-form">
        <img
          src={Smalllogo}
          className="flightinsights-wrapper-img"
          alt="Small Logo"
        />
        <h2 className="form-title">
          Global Internship Program 2025
          <br /> Interview Slot Booking
        </h2>

        {!isEmailChecked || !canProceed ? (
          <div className="form-card feedbackCard">
            <h3 className="card-title">Interview Slot Booking</h3>
            <div className="card-content">
              <div className="form-group">
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  onBlur={() => {
                    if (formData.email && !validateEmail(formData.email)) {
                      setErrors({
                        email: "Please enter a valid email address",
                      });
                    }
                  }}
                  required
                />
                {errors.email && (
                  <span className="error-message">{errors.email}</span>
                )}
              </div>
              {isEmailChecked && message && (
                <span
                  className={`warning-message__${
                    canProceed ? "success" : "error"
                  }`}
                >
                  {message}
                </span>
              )}
              <button
                type="button"
                onClick={handleEmailCheck}
                disabled={isSubmitting}
                className="submit-button"
                style={{ width: "40%" }}
              >
                {isSubmitting ? "Checking..." : "Check Eligibility"}
              </button>
              {!canProceed && isEmailChecked && !isSlotAlreadyBooked && (
                <button
                  onClick={() => (window.location.href = "/Flightregistration")}
                  className="submit-button"
                  style={{ width: "40%" }}
                >
                  Go to Registration
                </button>
              )}
            </div>
          </div>
        ) : (
          <>
            <div className="button-group">
              <button
                onClick={handleSlotBooked}
                className="submit-button"
                disabled={isSubmitting}
                style={{ margin: "0 auto 2rem auto", width: "50%" }}
              >
                {isSubmitting
                  ? "Confirming..."
                  : "After Confirming Your Interview Slot, CLICK HERE "}
              </button>
            </div>
            <div className="form-card">
              <h3 className="card-title">Schedule Your Interview</h3>
              <div className="card-content">
                <div
                  className="calendar-container"
                  style={{ position: "relative", minHeight: "600px" }}
                >
                  {isCalendarLoading && <LoadingSpinner />}
                  <iframe
                    src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ0GssKnzHE_ySq_Oeiq1peta4SltW4wAfg0bEk41PXK8A8IeXWyFWkjXd6qyaRnzCcjt6Fq61-R?gv=true"
                    style={{
                      border: 0,
                      width: "100%",
                      height: "600px",
                      opacity: isCalendarLoading ? 0 : 1,
                      transition: "opacity 0.3s ease",
                    }}
                    frameBorder="0"
                    title="Interview Slot Booking Calendar"
                    onLoad={() => setIsCalendarLoading(false)}
                  />
                </div>
                {message && (
                  <p
                    className={`message ${
                      message.includes("error") ? "error" : "success"
                    }`}
                  >
                    {message}
                  </p>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default InterviewSlotBooking;
